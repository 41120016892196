import React from 'react'

const Footer = () => {
	return (
		<>
			<div className='text-center m-10 text-gray-500 text-sm'>
				Copyright &copy; Likhith Shankar | ShaunLee is my alias
			</div>
		</>
	)
}

export default Footer
